import i18n from 'i18next';
import LanguageDetector from 'i18next-browser-languagedetector';
import Backend from 'i18next-locize-backend';
import { locizeEditorPlugin } from 'locize';
import locizeLastUsed from 'locize-lastused';
import { initReactI18next } from 'react-i18next';

import { environment } from './environment/environment';
import { appLanguageList } from './helpers/language.helper';
import { RaygunErrorHandlerService } from './services/raygun';
import crk from './translations/crk/translation.json';
import crs from './translations/crs/translation.json';
import cwd from './translations/cwd/translation.json';
import dak from './translations/dak/translation.json';
import en from './translations/en/translation.json';
import es from './translations/es/translation.json';
import fr from './translations/fr/translation.json';
import lkt from './translations/lkt/translation.json';
import moe from './translations/moe/translation.json';
import nsk from './translations/nsk/translation.json';
import ojw from './translations/ojw/translation.json';
import xnd from './translations/xnd/translation.json';

export const resources = {
  crk: { translation: crk },
  crs: { translation: crs },
  cwd: { translation: cwd },
  dak: { translation: dak },
  es: { translation: es },
  en: { translation: en },
  fr: { translation: fr },
  lkt: { translation: lkt },
  ojw: { translation: ojw },
  xnd: { translation: xnd },
  nsk: { translation: nsk },
  moe: { translation: moe },
} as const;

export type EnglishTranslation = (typeof resources)['en']['translation'];
type Namespace = keyof EnglishTranslation;
type Key = keyof EnglishTranslation[Namespace];
export type LabelTKey = keyof EnglishTranslation['labels'];
export type PersonalDetailsPageTKey = keyof EnglishTranslation['personalDetailsPage'];
export type ChatEndReasonsTKey = keyof EnglishTranslation['advocateChatEndReasons'];
export type ChatSubjectTKey = keyof EnglishTranslation['advocateChatSubjects'];
export type AdvocateResourcesTKey = keyof EnglishTranslation['advocateResources'];

const { logError } = RaygunErrorHandlerService();

const isLocal = environment.environment_deploy === 'local';
const isDev = environment.environment_deploy === 'dev';
const isProd = environment.environment_deploy === 'prod';
const supportedLngs = appLanguageList.map((l) => l.language_code);

if (isLocal) {
  i18n.use(locizeLastUsed).init({
    locizeLastUsed: {
      projectId: environment.locizeProjectId,
      apiKey: environment.locizeApiKey,
      referenceLng: 'en',
      version: 'latest',
      allowedHosts: ['localhost'],
    },
  });
}

i18n
  .use(LanguageDetector)
  .use(initReactI18next)
  .use(Backend)
  .use(locizeEditorPlugin({ show: !isProd }))
  .init({
    backend: {
      projectId: environment.locizeProjectId,
      apiKey: environment.locizeApiKey,
      referenceLng: 'en',
      version: isLocal || isDev ? 'latest' : environment.environment_deploy,
    },
    react: {
      bindI18n: 'languageChanged editorSaved',
    },
    returnNull: false,
    fallbackLng: 'en',
    supportedLngs: supportedLngs,
    interpolation: {
      escapeValue: false, // not needed for react as it escapes by default
    },
    resources,
    debug: isLocal,
    parseMissingKeyHandler: (missingKey) => {
      const translation = resources[i18n.language as keyof typeof resources].translation as EnglishTranslation;
      const [namespace, key] = missingKey.split('.') as [Namespace, Key];
      const result = translation?.[namespace]?.[key];
      if (result) {
        return result;
      } else {
        logError(new Error(`Missing translation for ${missingKey}.`), ['i18n', 'parseMissingKeyHandler']);
        return missingKey;
      }
    },
  });

export default i18n;
